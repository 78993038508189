*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.bottom-\[12\%\] {
  bottom: 12%;
}

.left-0 {
  left: 0;
}

.left-\[25\%\] {
  left: 25%;
}

.left-\[47\%\] {
  left: 47%;
}

.left-\[50\%\] {
  left: 50%;
}

.right-0 {
  right: 0;
}

.right-\[2\%\] {
  right: 2%;
}

.top-0, .top-\[0\] {
  top: 0;
}

.top-\[3\%\] {
  top: 3%;
}

.top-\[32\.5\%\] {
  top: 32.5%;
}

.top-\[4\.5\%\] {
  top: 4.5%;
}

.top-\[40\%\] {
  top: 40%;
}

.-z-10 {
  z-index: -10;
}

.z-10 {
  z-index: 10;
}

.z-40 {
  z-index: 40;
}

.z-\[-1\] {
  z-index: -1;
}

.z-\[-5\] {
  z-index: -5;
}

.z-\[1000\] {
  z-index: 1000;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.\!ml-auto {
  margin-left: auto !important;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-\[50\%\] {
  margin-left: 50%;
}

.ml-auto {
  margin-left: auto;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-12 {
  margin-right: 3rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-auto {
  margin-right: auto;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-auto {
  margin-top: auto;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.aspect-\[14\/12\] {
  aspect-ratio: 14 / 12;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-5 {
  height: 1.25rem;
}

.h-8 {
  height: 2rem;
}

.h-\[20\%\] {
  height: 20%;
}

.h-\[25\%\] {
  height: 25%;
}

.h-\[27\%\] {
  height: 27%;
}

.h-\[30\%\] {
  height: 30%;
}

.h-\[calc\(100vh-3\.5rem\)\] {
  height: calc(100vh - 3.5rem);
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-full {
  max-height: 100%;
}

.min-h-0 {
  min-height: 0;
}

.w-12 {
  width: 3rem;
}

.w-20 {
  width: 5rem;
}

.w-5 {
  width: 1.25rem;
}

.w-\[20\%\] {
  width: 20%;
}

.w-\[22\.5\%\] {
  width: 22.5%;
}

.w-\[25\%\] {
  width: 25%;
}

.w-\[30\%\] {
  width: 30%;
}

.w-\[58vw\] {
  width: 58vw;
}

.w-\[calc\(100\%-32px\)\] {
  width: calc(100% - 32px);
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-0 {
  min-width: 0;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-xl {
  max-width: 36rem;
}

.flex-1 {
  flex: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.origin-center {
  transform-origin: center;
}

.-translate-y-\[100\%\] {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1 {
  --tw-translate-y: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[0\.2rem\] {
  --tw-translate-y: .2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.snap-start {
  scroll-snap-align: start;
}

.list-disc {
  list-style-type: disc;
}

.list-none {
  list-style-type: none;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-\[1fr\,1fr\] {
  grid-template-columns: 1fr 1fr;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-12 {
  column-gap: 3rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.self-center {
  align-self: center;
}

.justify-self-stretch {
  justify-self: stretch;
}

.overflow-x-auto {
  overflow-x: auto;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-brandbg-200 {
  --tw-border-opacity: 1;
  border-color: rgb(160 162 157 / var(--tw-border-opacity));
}

.border-brandbg-300 {
  --tw-border-opacity: 1;
  border-color: rgb(121 125 118 / var(--tw-border-opacity));
}

.border-brandbg-400 {
  --tw-border-opacity: 1;
  border-color: rgb(89 95 85 / var(--tw-border-opacity));
}

.border-brandbg-500 {
  --tw-border-opacity: 1;
  border-color: rgb(71 77 66 / var(--tw-border-opacity));
}

.\!bg-brand-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(128 217 152 / var(--tw-bg-opacity)) !important;
}

.\!bg-brandbg-900\/80 {
  background-color: #1c1f1acc !important;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-brand-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(128 217 152 / var(--tw-bg-opacity));
}

.bg-brand-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(101 189 126 / var(--tw-bg-opacity));
}

.bg-brand-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 246 178 / var(--tw-bg-opacity));
}

.bg-brand-50\/20 {
  background-color: #9cf6b233;
}

.bg-brand-50\/50 {
  background-color: #9cf6b280;
}

.bg-brand-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 82 40 / var(--tw-bg-opacity));
}

.bg-brand-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 57 26 / var(--tw-bg-opacity));
}

.bg-brandbg-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 220 218 / var(--tw-bg-opacity));
}

.bg-brandbg-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 236 235 / var(--tw-bg-opacity));
}

.bg-brandbg-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(28 31 26 / var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-40 {
  --tw-bg-opacity: .4;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-60 {
  --tw-bg-opacity: .6;
}

.bg-gradient-radial {
  background-image: radial-gradient(var(--tw-gradient-stops));
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.from-\[\#00000088\] {
  --tw-gradient-from: #0008 var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #0000 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent {
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #0000 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-\[\#00000088\] {
  --tw-gradient-to: #0008 var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.to-\[\#ffffff44\] {
  --tw-gradient-to: #fff4 var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.stroke-white {
  stroke: #fff;
}

.object-cover {
  object-fit: cover;
}

.object-\[0_40\%\] {
  object-position: 0 40%;
}

.object-\[0_60\%\] {
  object-position: 0 60%;
}

.object-\[22\%_100\%\] {
  object-position: 22% 100%;
}

.object-\[38\%_28\%\] {
  object-position: 38% 28%;
}

.object-\[80\%_0\] {
  object-position: 80% 0;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-center {
  text-align: center;
}

.align-top {
  vertical-align: top;
}

.font-\[\'KyivType_Sans_Heavy\'\] {
  font-family: KyivType Sans Heavy;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.tracking-widest {
  letter-spacing: .1em;
}

.\!text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

.\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.text-brand-200 {
  --tw-text-opacity: 1;
  color: rgb(101 189 126 / var(--tw-text-opacity));
}

.text-brand-500 {
  --tw-text-opacity: 1;
  color: rgb(2 109 55 / var(--tw-text-opacity));
}

.text-brand-600 {
  --tw-text-opacity: 1;
  color: rgb(0 82 40 / var(--tw-text-opacity));
}

.text-brand-800 {
  --tw-text-opacity: 1;
  color: rgb(0 57 26 / var(--tw-text-opacity));
}

.text-brand-900 {
  --tw-text-opacity: 1;
  color: rgb(0 33 12 / var(--tw-text-opacity));
}

.text-brandbg-50 {
  --tw-text-opacity: 1;
  color: rgb(236 236 235 / var(--tw-text-opacity));
}

.text-brandbg-600 {
  --tw-text-opacity: 1;
  color: rgb(64 69 59 / var(--tw-text-opacity));
}

.text-brandbg-700 {
  --tw-text-opacity: 1;
  color: rgb(57 62 53 / var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-gray-700 {
  --tw-shadow-color: #374151;
  --tw-shadow: var(--tw-shadow-colored);
}

.outline {
  outline-style: solid;
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[border-color\] {
  transition-property: border-color;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[shadow\,background\] {
  transition-property: shadow, background;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[stroke-dashoffset\] {
  transition-property: stroke-dashoffset;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[transform\] {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-100 {
  transition-duration: .1s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-75 {
  transition-duration: 75ms;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }

  body {
    color: #fff;
    --tw-bg-opacity: 1;
    background-color: rgb(28 31 26 / var(--tw-bg-opacity));
  }
}

@font-face {
  font-family: KyivType Sans Heavy;
  src: url("KyivTypeHeavy.070a95ef.woff2") format("woff2");
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Poppins-400.1110a71c.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("Poppins-500.637939bc.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
  font-family: Poppins;
}

.before\:content-\[\"①_\"\]:before {
  --tw-content: "① ";
  content: var(--tw-content);
}

.before\:content-\[\"②_\"\]:before {
  --tw-content: "② ";
  content: var(--tw-content);
}

.before\:content-\[\"③_\"\]:before {
  --tw-content: "③ ";
  content: var(--tw-content);
}

.hover\:bg-brand-100\/30:hover {
  background-color: #80d9984d;
}

.hover\:bg-brand-100\/70:hover {
  background-color: #80d998b3;
}

.hover\:bg-white\/40:hover {
  background-color: #fff6;
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:border-brand-400:focus {
  --tw-border-opacity: 1;
  border-color: rgb(44 135 78 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.hover\:enabled\:shadow-md:enabled:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:enabled\:shadow-gray-700:enabled:hover {
  --tw-shadow-color: #374151;
  --tw-shadow: var(--tw-shadow-colored);
}

.disabled\:border-gray-500:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.disabled\:text-brandbg-400:disabled {
  --tw-text-opacity: 1;
  color: rgb(89 95 85 / var(--tw-text-opacity));
}

.disabled\:opacity-75:disabled {
  opacity: .75;
}

.disabled\:shadow-sm:disabled, .hover\:disabled\:shadow-sm:disabled:hover {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.peer:checked ~ .peer-checked\:bg-brand-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 109 55 / var(--tw-bg-opacity));
}

.peer:hover ~ .peer-hover\:bg-brandbg-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 236 235 / var(--tw-bg-opacity));
}

.peer:hover ~ .peer-hover\:shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.peer:checked:hover ~ .peer-checked\:peer-hover\:bg-brand-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(44 135 78 / var(--tw-bg-opacity));
}

@media (prefers-color-scheme: dark) {
  .dark\:border-brandbg-600 {
    --tw-border-opacity: 1;
    border-color: rgb(64 69 59 / var(--tw-border-opacity));
  }

  .dark\:bg-brand-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(73 162 102 / var(--tw-bg-opacity));
  }

  .dark\:bg-brand-800\/50 {
    background-color: #00391a80;
  }

  .dark\:bg-brandbg-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(57 62 53 / var(--tw-bg-opacity));
  }

  .dark\:bg-brandbg-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(43 46 40 / var(--tw-bg-opacity));
  }

  .dark\:bg-brandbg-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(28 31 26 / var(--tw-bg-opacity));
  }

  .dark\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(252 165 165 / var(--tw-bg-opacity));
  }

  .dark\:bg-opacity-40 {
    --tw-bg-opacity: .4;
  }

  .dark\:stroke-black {
    stroke: #000;
  }

  .dark\:text-brand-200 {
    --tw-text-opacity: 1;
    color: rgb(101 189 126 / var(--tw-text-opacity));
  }

  .dark\:text-brand-300 {
    --tw-text-opacity: 1;
    color: rgb(73 162 102 / var(--tw-text-opacity));
  }

  .dark\:text-brand-400 {
    --tw-text-opacity: 1;
    color: rgb(44 135 78 / var(--tw-text-opacity));
  }

  .dark\:text-brandbg-100 {
    --tw-text-opacity: 1;
    color: rgb(219 220 218 / var(--tw-text-opacity));
  }

  .dark\:text-brandbg-200 {
    --tw-text-opacity: 1;
    color: rgb(160 162 157 / var(--tw-text-opacity));
  }

  .dark\:text-brandbg-800 {
    --tw-text-opacity: 1;
    color: rgb(43 46 40 / var(--tw-text-opacity));
  }

  .dark\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgb(74 222 128 / var(--tw-text-opacity));
  }

  .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .dark\:hover\:bg-black\/\[0\.12\]:hover {
    background-color: #0000001f;
  }

  .dark\:hover\:bg-brand-700\/70:hover {
    background-color: #004521b3;
  }

  .dark\:hover\:text-white:hover, .hover\:dark\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .dark\:disabled\:border-gray-600:disabled {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity));
  }

  .dark\:disabled\:text-brandbg-600:disabled {
    --tw-text-opacity: 1;
    color: rgb(64 69 59 / var(--tw-text-opacity));
  }

  .peer:checked ~ .dark\:peer-checked\:bg-brand-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(101 189 126 / var(--tw-bg-opacity));
  }

  .peer:hover ~ .dark\:peer-hover\:bg-brandbg-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(57 62 53 / var(--tw-bg-opacity));
  }

  .peer:checked:hover ~ .dark\:peer-checked\:peer-hover\:bg-brand-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(128 217 152 / var(--tw-bg-opacity));
  }
}

@media (min-width: 400px) {
  .xs\:ml-32 {
    margin-left: 8rem;
  }
}

@media (min-width: 640px) {
  .sm\:float-right {
    float: right;
  }

  .sm\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .sm\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .sm\:ml-0 {
    margin-left: 0;
  }

  .sm\:ml-12 {
    margin-left: 3rem;
  }

  .sm\:mt-10 {
    margin-top: 2.5rem;
  }

  .sm\:mt-16 {
    margin-top: 4rem;
  }

  .sm\:h-6 {
    height: 1.5rem;
  }

  .sm\:h-7 {
    height: 1.75rem;
  }

  .sm\:h-screen {
    height: 100vh;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:min-w-\[24rem\] {
    min-width: 24rem;
  }

  .sm\:columns-3 {
    columns: 3;
  }

  .sm\:items-end {
    align-items: flex-end;
  }

  .sm\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .sm\:bg-none {
    background-image: none;
  }

  .sm\:p-8 {
    padding: 2rem;
  }

  .sm\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:pb-8 {
    padding-bottom: 2rem;
  }

  .sm\:pr-8 {
    padding-right: 2rem;
  }

  .sm\:pt-8 {
    padding-top: 2rem;
  }

  .sm\:text-end {
    text-align: end;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .sm\:text-\[2rem\] {
    font-size: 2rem;
  }

  .sm\:leading-10 {
    line-height: 2.5rem;
  }
}

@media (min-width: 768px) {
  .md\:h-screen {
    height: 100vh;
  }

  .md\:w-96 {
    width: 24rem;
  }

  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:leading-5 {
    line-height: 1.25rem;
  }
}

@media (min-width: 1024px) {
  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

@media (min-width: 1280px) {
  .xl\:mt-16 {
    margin-top: 4rem;
  }

  .xl\:inline {
    display: inline;
  }

  .xl\:h-screen {
    height: 100vh;
  }

  .xl\:w-full {
    width: 100%;
  }

  .xl\:max-w-2xl {
    max-width: 42rem;
  }
}

.\[\&\>\*\]\:inline-block > * {
  display: inline-block;
}

.peer:checked ~ .peer-checked\:\[\&\>\:first-child\>\:first-child\]\:stroke-dashoffset-0 > :first-child > :first-child {
  stroke-dashoffset: 0;
}

.\[\&\>img\]\:rounded-xl > img {
  border-radius: .75rem;
}

.\[\&\>img\]\:shadow-xl > img {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.\[\&\>li\]\:snap-start > li {
  scroll-snap-align: start;
}

.\[\&\>li\]\:snap-always > li {
  scroll-snap-stop: always;
}

.\[\&\>li\]\:before\:text-brand-600 > li:before {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: rgb(0 82 40 / var(--tw-text-opacity));
}

@media (prefers-color-scheme: dark) {
  .\[\&\>li\]\:dark\:before\:text-brand-200 > li:before {
    content: var(--tw-content);
    --tw-text-opacity: 1;
    color: rgb(101 189 126 / var(--tw-text-opacity));
  }
}

/*# sourceMappingURL=index.a6a2cc55.css.map */
